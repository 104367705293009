import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Typography, Dialog } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from "next-export-i18n";

interface TypeLoading {
  open: boolean;
  onClose?: (args: any) => void;
}

const Loading = (props: TypeLoading) => {
  const classes = useStyles();
  const { open, onClose } = props;
  const { t } = useTranslation();

  return (
    <>
      <Dialog disableScrollLock={true} open={open} onClose={onClose && onClose}>
        <div className={classes.root}>
          <CircularProgress />
          <Typography className={classes.text}>
            {t("loading.please-wait")}
          </Typography>
        </div>
      </Dialog>
    </>
  );
};

export default Loading;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      textAlign: "center",
      padding: 16,
    },
    text: {
      fontWeight: "bold",
      paddingTop: 10,
    },
  })
);
