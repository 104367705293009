import firebase from "firebase";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAnerY_xoy5Glt8Efe15KJPC45knP3WITg",
  authDomain: "great-hotel.firebaseapp.com",
  databaseURL: "https://great-hotel.firebaseio.com",
  projectId: "great-hotel",
  storageBucket: "great-hotel.appspot.com",
  messagingSenderId: "76179694296",
  appId: "1:76179694296:web:94401f14573c9b7ea92dad",
  measurementId: "G-E4G0Z57NCD",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const provider = new firebase.auth.GoogleAuthProvider();

export const loginWithGoogle = () =>
  firebase
    .auth()
    .signInWithPopup(provider)
    .then(async function (result) {
      // This gives you a Google Access Token. You can use it to access the Google API.
      var token = await result.user.getIdToken();
      // The signed-in user info.
      return token;
    })
    .catch(function (error) {});

export const login = (email: string, password: string) =>
  firebase.auth().signInWithEmailAndPassword(email, password);

export const register = (email: string, password: string) =>
  firebase.auth().createUserWithEmailAndPassword(email, password);

var auth = firebase.auth();
export { auth, firebase };
